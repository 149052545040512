import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"

import styled from "@emotion/styled"

// Styles
const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Wrapper = styled.section`
  margin: 0rem 1rem 1rem 1rem;
  ${mq[2]} {
    margin: 0rem 1.5rem 1.5rem 1.5rem;
  }
  ${mq[3]} {
    margin: 0rem 2rem 2rem 2rem;
  }
`

// Config

function PageRte(props) {
  return (
    <Wrapper>
      <PortableText blocks={props.text}></PortableText>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Wrapper>
  )
}

export default PageRte
