import React from "react"
import styled from "@emotion/styled"

const breakpoints = [576, 768, 992, 1200, 1600]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1rem;
  gap: 1rem;
  ${mq[0]} {
    grid-template-columns: 3fr 1fr;
  }
  ${mq[2]} {
    margin: 1.5rem;
    gap: 1.5rem;
  }
  ${mq[3]} {
    grid-template-columns: 4fr 2fr;

    margin: 2rem;
    gap: 2rem;
  }
  ${mq[4]} {
    grid-template-columns: 1fr 1fr;
  }
`

function HeroText(props) {
  return (
    <Wrapper>
      <h1>{props.title}</h1>
      <p>{props.subTitle}</p>
    </Wrapper>
  )
}

export default HeroText
