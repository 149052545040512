import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"

import { getFluidGatsbyImage } from "gatsby-source-sanity"

// Styles
const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const SplitGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const GridLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  text-align: left;
  margin: 1em 0.5em 0 1em;
  grid-column: 1 / span 2;

  ${mq[1]} {
    grid-column: 1 / span 1;
  }
  ${mq[2]} {
    margin: 0.75em 1.5em 0 1.5em;
  }
  ${mq[3]} {
    margin: 2em 1em 0 2em;
  }
  a {
    text-decoration: none;
    color: black;
  }
`

const GridRight = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1;

  ${mq[1]} {
    background: green;
    grid-column: 2 / span 1;
  }
`

const Parent = styled.div`
  position: relative;
`
const FakeBgImage = styled(Image)`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
  }

  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  } */
`

// Config
const sanityConfig = { projectId: "1inc05pd", dataset: "production" }

function SplitHero(props) {
  const fluidProps1 = getFluidGatsbyImage(
    props.featuredProj.mainImage,
    { maxWidth: 1000 },
    sanityConfig
  )

  return (
    <SplitGrid>
      <GridLeft>
        <Link to={props.featuredProj.slug.current}>
          <h2>{props.featuredProj.title}</h2>
        </Link>
        <p>{props.featuredProj.subtitle}</p>
      </GridLeft>
      <GridRight>
        <Parent>
          <Link to={props.featuredProj.slug.current}>
            <FakeBgImage fluid={fluidProps1} />
          </Link>
          {/* <Image fluid={fluidProps1} /> */}
        </Parent>
      </GridRight>
    </SplitGrid>
  )
}

export default SplitHero
