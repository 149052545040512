import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"

import { getFluidGatsbyImage } from "gatsby-source-sanity"

// Styles
const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const HeroWrap = styled.div`
  margin-bottom: 2rem;
  ${mq[2]} {
    margin-bottom: 2rem;
  }
  ${mq[3]} {
    margin-bottom: 3rem;
  }
`

const Parent = styled.div`
  position: relative;
`
const FakeBgImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
  }

  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`

const Wrapper = styled.section`
  margin: 1rem 1rem 0 1rem;
  ${mq[2]} {
    margin: 1.5rem 1.5rem 0 1.5rem;
  }
  ${mq[3]} {
    margin: 2rem 2rem 0 2rem;
  }
`

const WorkTitle = styled(Link)`
  color: #111;
  text-decoration: none;
`

const WorkLink = styled(Link)`
  border: 1px solid #111;
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #111;
  font-size: 0.875rem;
  display: inline-block;
  margin-top: 1rem;

  :hover {
    background-color: #111;
    color: #fff;
  }
`

// const Arrow = styled.svg`
//   width: 5rem;
// `

// const Content = styled.div`
//   position: absolute;
//   top: 0;
//   height: 100%;
//   width: 100%;
// `

// Config
const sanityConfig = { projectId: "1inc05pd", dataset: "production" }

function HeroProject(props) {
  const fluidProps1 = getFluidGatsbyImage(
    props.featuredProj.mainImage,
    { maxWidth: 1000 },
    sanityConfig
  )

  return (
    <HeroWrap>
      <Parent>
        <Link to={props.featuredProj.slug.current}>
          <FakeBgImage fluid={fluidProps1} />
        </Link>
        {/* <Image fluid={fluidProps1} /> */}
      </Parent>
      <Wrapper>
        <WorkTitle to={props.featuredProj.slug.current}>
          <h2>{props.featuredProj.title}</h2>
        </WorkTitle>
        <p>{props.featuredProj.subtitle}</p>
        <WorkLink to={props.featuredProj.slug.current}>
          View Case Study &rarr;
        </WorkLink>
      </Wrapper>
    </HeroWrap>
  )
}

export default HeroProject
